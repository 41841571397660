import {Button, Card, CardContent, Fab, Grid, TextField, Typography} from "@material-ui/core"
import React, {ChangeEvent, useEffect, useState} from "react"
import * as queryString from "query-string"
import {Layout} from "../../components/Layout"
import {GrowGrid, ResponsiveContainer} from "../../components/Shared"
import styled from "@emotion/styled"
import {GrFormAdd} from "@react-icons/all-files/gr/GrFormAdd"
import {extractFromParams} from "../../api/Utils"
import {useLocation} from "@reach/router"
import {AiFillDelete} from "@react-icons/all-files/ai/AiFillDelete"


const FullWidthTextField = styled(TextField)({
  width: "100%"
})
const LowerThird = () => {
  const location = useLocation()
  const [state, setState] = useState({
    timeout: "10",
    newsUp: "Recalbox",
    newsDown: "News",
    topLines: [""],
    bottomLines: [""]
  })

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }
    const q = queryString.parseUrl(window.location.search)
    setState({
      ...state,
      bottomLines: extractFromParams(q.query.bottomLines),
      topLines: extractFromParams(q.query.topLines)
    })
  }, [])

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const newState = {...state, [ev.target.name]: ev.target.value}
    setState(newState)
    history.replaceState({}, "", "?" + queryString.stringify(newState))
  }
  const handleDelete = (index: number) => {
    const topLines = [...state.topLines]
    topLines.splice(index, 1)
    const bottomLines = [...state.bottomLines]
    bottomLines.splice(index, 1)
    const newState = {...state, topLines, bottomLines}
    setState(newState)
    history.replaceState({}, "", "?" + queryString.stringify(newState))
  }
  const handleLineChange = (position: "topLines" | "bottomLines", line: number, text: string) => {
    const lines = position === "topLines" ? state.topLines : state.bottomLines
    const newLines = [...lines]
    newLines[line] = text
    const newState = {...state, [position]: newLines}
    setState(newState)
    history.replaceState({}, "", "?" + queryString.stringify(newState))

  }
  const start = async () => {
    if (window !== undefined) {
      window.open("/fr/utils/lowerthird/?" + queryString.stringify(state), "_blank")
    }
  }


  return (
    <Layout title={"Lower Third Twitch"} noNav hideCookieConsent>
      <ResponsiveContainer>
        <Card>
          <CardContent>
            <Typography variant={"h2"} gutterBottom>La page à fab</Typography>
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField label="News Text Upper" variant="outlined" name="newsUp" value={state.newsUp}
                             onChange={handleChange}/>
                </Grid>
                <Grid item xs={12}>
                  <TextField label="News Text Lower" variant="outlined" name="newsDown" value={state.newsDown}
                             onChange={handleChange}/>
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Timeout in seconds" variant="outlined" name="timeout"
                             type="number" value={state.timeout} onChange={handleChange}/>
                </Grid>
                {state.topLines.map((text, index) => {
                  return (
                    <React.Fragment>
                      <Grid item xs={12} container>
                        <GrowGrid item>
                          <FullWidthTextField
                            error={state.bottomLines[index] !== "" ? text.length > 43 : text.length > 38}
                            label={`News ${index + 1}, ligne haute`} variant="outlined" value={text}
                            onChange={(ev) => handleLineChange("topLines", index, ev.target.value)}/>
                        </GrowGrid>
                        <Grid item>
                          <Fab color="default" aria-label="delete" onClick={() => handleDelete(index)}>
                            <AiFillDelete></AiFillDelete>
                          </Fab>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FullWidthTextField error={state.bottomLines[index].length > 70}
                                            label={`News ${index + 1}, ligne basse`} variant="outlined"
                                            value={state.bottomLines[index]}
                                            onChange={(ev) => handleLineChange("bottomLines", index, ev.target.value)}/>
                      </Grid>
                    </React.Fragment>
                  )
                })
                }
                <Grid item xs={12}>
                  <Fab onClick={() => {
                    setState({
                      ...state,
                      topLines: [...state.topLines, ""],
                      bottomLines: [...state.bottomLines, ""]
                    })
                  }}>
                    <GrFormAdd/>
                  </Fab>
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={start} variant={"contained"} color={"primary"}>
                    Start Lowerthird
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </ResponsiveContainer>
    </Layout>
  )
}
export default LowerThird
